// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}


// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
    .open-menu{
        display: none;
    }
    
    .sidebar-container{
        width: 260px;
        position: inherit;
        height: 100vh;
        display: flex;
        z-index: 1510;
        height: 100vh;
        flex-direction: column;
        box-shadow: 3px 3px 3px rgba(51, 51, 51, 0.2);

    }
    .site-content{
        width: calc(100vw - 260px);
        overflow-y: scroll;
    
    }
    body {
        min-height: 100vh;
        min-height: -webkit-fill-available;
    }
    main {
        height: 100vh;
        height: -webkit-fill-available;
        max-height: 100vh;
        overflow-x: auto;
        overflow-y: hidden;
    }
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {
}
.open-menu { 
    position: fixed; top: 15px; left: 10px; z-index: 1500; box-shadow: 3px 3px 3px rgba(51, 51, 51, 0.2); 
    padding: 0px 8px !important;
}
.close-menu{
    padding: 0px 8px !important;
    font-size: 28px;
}

.modal{
    z-index: 1600;
}