.sidebar-container{
    display: none;
    transition: all .3s ease !important;
}


.sidebar-container.active{
    width: 260px;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    z-index: 1510;
    height: 100vh;
    flex-direction: column;
    box-shadow: 4px 4px 5px rgba(51, 51, 51, 0.3);
    transition: all .3s ease !important;




    // d-flex flex-column flex-shrink-0
}
.light-shadow{
    box-shadow: 3px 3px 5px rgba(51, 51, 51, 0.3);
}
.site-content{
    width: 100%;
    overflow-y: scroll;

}


html {
    height: -webkit-fill-available;
}



.dropdown-toggle {
    outline: 0;
}

.btn-toggle {
    padding: 0.25rem 0.5rem;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.65);
    background-color: transparent;
}
.btn-toggle:hover,
.btn-toggle:focus {
    color: rgba(0, 0, 0, 0.85);
    background-color: #d2f4ea;
}

.btn-toggle::before {
    width: 1.25em;
    line-height: 0;
    content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%280,0,0,.5%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
    transition: transform 0.35s ease;
    transform-origin: 0.5em 50%;
}

.btn-toggle[aria-expanded="true"] {
    color: rgba(0, 0, 0, 0.85);
}
.btn-toggle[aria-expanded="true"]::before {
    transform: rotate(90deg);
}

.btn-toggle-nav a {
    padding: 0.1875rem 0.5rem;
    margin-top: 0.125rem;
    margin-left: 1.25rem;
}
.btn-toggle-nav a:hover,
.btn-toggle-nav a:focus {
    background-color: #d2f4ea;
}

.scrollarea {
    overflow-y: auto;
}

.bd-placeholder-img {
    font-size: 1.125rem;
    text-anchor: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

@media (min-width: 768px) {
    .bd-placeholder-img-lg {
        font-size: 3.5rem;
    }
}

.b-example-divider {
    height: 3rem;
    background-color: rgba(0, 0, 0, 0.1);
    border: solid rgba(0, 0, 0, 0.15);
    border-width: 1px 0;
    box-shadow: inset 0 0.5em 1.5em rgba(0, 0, 0, 0.1), inset 0 0.125em 0.5em rgba(0, 0, 0, 0.15);
}

.b-example-vr {
    flex-shrink: 0;
    // width: 1.5rem;
    height: 100vh;
}

.bi {
    // vertical-align: -0.125em;
    fill: currentColor;
}

.nav-scroller {
    position: relative;
    z-index: 2;
    // height: 2.75rem;
    overflow-y: hidden;
}

.nav-scroller .nav {
    display: flex;
    flex-wrap: nowrap;
    padding-bottom: 1rem;
    margin-top: -1px;
    overflow-x: auto;
    text-align: center;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
}


.nav.nav-pills{
    .nav-link{
        margin-top: 1px;
        margin-bottom: 1px;
    }
    .nav-link.active{
        background-color: #00b1eb;
    }
    .nav-link:hover{
        background-color: #00b1eb;
    }
}

.font-bold{
    font-weight: 600;
}