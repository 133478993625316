



$theme-colors: (
    "primary": #0e2b63,
    "info": #00b1eb,
    "danger": #e72582,
    "warning": #ffbb00,
    "secondary": #004f9f,
    "light": #e3ecef,
    "success": #51796F,
    "dark": #0e2b63,
    "white": #ffffff,
    "amber": #FFBF00
);

.bg-light-transparent{
    background-color: #e3ecef94
}

@import "../../node_modules/bootstrap/scss/bootstrap.scss";

.scroll-flow{
    overflow: scroll;
}
.btn-light{
    background-color: #99a4ba;
}

html{
    scroll-behavior: smooth !important;
}

.inline-btn{
    display: inline-block !important;
}

.font-14{
    font-size: 14px !important;
}
.font-16{
    font-size: 16px !important;
}
.font-18{
    font-size: 18px !important;
}
.font-30{
    font-size: 30px !important;
}
table{
    .btn-primary{
        background-color: #51796F;
    }
    .btn-sm{
        padding: 2px 4px;
        font-size: 12px !important;
    }
}

.btn-smaller{
    padding: 2px 4px;
    font-size: 12px !important;
    border-radius: 3px;
}