// .rdrDateRangePickerWrapper{
//     display: flex !important;
//     flex-direction: column !important;
// }

@media (max-width: 576px) {
    .rdrDateRangePickerWrapper{
        flex-direction: column !important;
        display: flex !important;

    }
}