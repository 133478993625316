.dashboard-menu{
    border-radius: 8px;
    // border: 3px solid #00b1eb;
transition: all .3s ease;
    text-decoration: none;
    &:hover{
        background-color: #00b1eb !important;

    }
}
.text-italic{
    font-style: italic;
}

.dashboard-link{
    text-decoration: none;
}

.modal-60w {
    width: 95%;
    max-width: none!important;
}
@media (min-width: 768px) {
    .modal-60w{
        width: 60%;
        max-width: none!important;
    }
}
.modal-80w {
    width: 80%;
    max-width: none!important;
}

.modal-90w {
    width: 90%;
    max-width: none!important;
}

.modal-95w {
    width: 95%;
    max-width: none!important;
}