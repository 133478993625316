// .login-container {
//     height: 90vh;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     background-color: #f5f5f5;
// }
.login-layout {
    border: 1px solid rgba(53, 52, 52, 0.596);
    border-radius: 8px;
}

.custom-auth-btn {
    border-radius: 30px;
    position: relative;
    border: 1px solid gray;
}
.custom-auth-btn img {
    height: 36px;
    position: absolute;
    top: 10px;
    left: 012px;
}
.custom-auth-btn:hover {
    background-color: #c9c9c9;
}
.font-7{
    font-size: 7px;
}
// .form-log {
//     padding-top: 10px;
//     font-size: 14px;
//     margin-top: 30px;
// }

// .card-title {
//     font-weight: 300;
// }

// .login-form {
//     width: 330px;
//     margin: 20px;
// }

// .sign-up {
//     text-align: center;
//     padding: 20px 0 0;
// }

// .alert {
//     margin-bottom: -30px;
//     font-size: 13px;
//     margin-top: 20px;
// }
